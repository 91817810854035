import React, { useState, useEffect } from "react";
import MainLayout from "../../layouts/MainLayout";
import { FaComments, FaCommentDots, FaStar } from "react-icons/fa";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
  TooltipProps,
} from "recharts";
import { Pagination, Table, Modal, Button } from "flowbite-react";
import { getChatCounts } from "../../api/analytics";
import { ChatLog } from "../../utils/types/chat";
import { deleteChat, getChatLogs } from "../../api/chat";
import moment from "moment";
import "moment-timezone";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/helpers/authWrapper";
import Spinner from "../../components/Spinner";
import ExportCSV from "../../components/export/csv";

const COLORS = ["#D0EE52", "#82ca9d", "#ffc658", "#d0ed57"];

interface BarChartData {
  date: string;
  [key: string]: number | string;
}

interface PieChartData {
  name: string;
  value: number;
  total: number;
}

const Dashboard: React.FC = () => {
  const [barChartData, setBarChartData] = useState<BarChartData[]>([]);
  const [pieChartData, setPieChartData] = useState<PieChartData[]>([]);
  const [selectedDomain, setSelectedDomain] = useState<string>("All");
  const [logsByPage, setLogsByPage] = useState<{ [key: number]: ChatLog[] }>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage, setLogsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [liveChats, setLiveChats] = useState(0);
  const [domainNames, setDomainNames] = useState<string[]>([]);
  const [totalChats, setTotalChats] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLogId, setSelectedLogId] = useState<number | null>(null);
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();
  const { loading, user } = useAuth();
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const [averageRating, setAverageRating] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setDashboardLoading(true);
      try {
        const chatCounts = await getChatCounts(user?.id || 0);
        const combinedSessions: BarChartData[] = [];
        const domainNames = [
          ...new Set(chatCounts.map((item) => item.domain_name)),
        ];

        setDomainNames(domainNames);

        const dataMap: { [key: string]: BarChartData } = {};
        chatCounts.forEach((item) => {
          if (!dataMap[item.date]) {
            dataMap[item.date] = { date: item.date };
          }
          dataMap[item.date][item.domain_name] = item.count;
        });

        const last7Days = getLast7Days();
        last7Days.forEach((date) => {
          combinedSessions.push(dataMap[date] || { date });
        });

        setBarChartData(combinedSessions);

        const totalVisitors = chatCounts.reduce(
          (total, item) => total + item.count,
          0
        );

        const pieData = domainNames.map((domain_name) => {
          const domainVisitors = chatCounts
            .filter((item) => item.domain_name === domain_name)
            .reduce((sum, item) => sum + item.count, 0);
          return {
            name: domain_name,
            value: Number(((domainVisitors / totalVisitors) * 100).toFixed(2)),
            total: domainVisitors,
          };
        });

        setPieChartData(pieData.length > 1 ? pieData : []);

        if (selectedDomain === "All") {
          const totalChatsAll = chatCounts.reduce(
            (total, item) => total + item.count,
            0
          );
          setTotalChats(totalChatsAll);

          const liveChats = chatCounts[0].active_chat || 0;
          setLiveChats(liveChats);
        } else {
          const totalChatsDomain = chatCounts
            .filter((item) => item.domain_name === selectedDomain)
            .reduce((total, item) => total + item.count, 0);
          setTotalChats(totalChatsDomain);

          const filteredDomain = chatCounts.find(
            (item) => item.domain_name === selectedDomain
          );
          const liveChatsDomain = filteredDomain
            ? filteredDomain.active_chat || 0
            : 0;
          setLiveChats(liveChatsDomain);
        }

        const chatLogs = await getChatLogs(10,0);
        if (chatLogs) {
          setLogsByPage((prevLogs) => ({
            ...prevLogs,
            [currentPage]: chatLogs,
          }));
          const averageRating = calculateAverageRating(chatLogs);
          setAverageRating(averageRating);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setDashboardLoading(false);
      }
    };

    fetchData();
  }, [selectedDomain, user]);

  useEffect(() => {
    const offset = (currentPage - 1) * logsPerPage;
    const fetchChatLogs = async () => {
      const chatLogs = await getChatLogs(logsPerPage, offset);
      if (chatLogs) {
        setLogsByPage((prevLogs) => ({
          ...prevLogs,
          [currentPage]: chatLogs,
        }));
        const averageRating = calculateAverageRating(chatLogs);
        setAverageRating(averageRating);
      }
    };
  
    fetchChatLogs();
  }, [logsPerPage, currentPage]);
  

  

  const calculateAverageRating = (logs: ChatLog[]) => {
    const ratedLogs = logs.filter((log) => log.rating !== "");
    const totalRating = ratedLogs.reduce(
      (sum, log) => sum + parseFloat(log.rating),
      0
    );
    return ratedLogs.length > 0
      ? (totalRating / ratedLogs.length).toFixed(2)
      : "No ratings";
  };

  const getLast7Days = () => {
    const today = new Date();
    const days = [];
    for (let i = 6; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      days.push(date.toISOString().split("T")[0]);
    }
    return days;
  };

  const handleDomainChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDomain(event.target.value);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleLogsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setLogsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const getFilteredData = () => {
    if (selectedDomain === "All") {
      return barChartData.map((data) => {
        const filteredData: { [key: string]: number | string } = {
          date: data.date,
        };

        domainNames.forEach((domain) => {
          filteredData[domain] = data[domain] || 0;
        });

        return filteredData;
      });
    } else {
      return barChartData.map((data) => ({
        date: data.date,
        [selectedDomain]: data[selectedDomain as keyof BarChartData] || 0,
      }));
    }
  };

  const extractNameAndEmail = (
    message: string
  ): { name: string; email: string } => {
    const userMessage = message
      .split("\n")
      .find((part) => part.startsWith("User:"));
    if (!userMessage) return { name: "Unknown", email: "Unknown" };

    const nameMatch = userMessage.match(/name is ([\w\s'.-]+) and/i);
    const emailMatch = userMessage.match(
      /email is (([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})|([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+))/i
    );

    const name = nameMatch ? nameMatch[1].trim() : "Unknown";
    const email = emailMatch ? emailMatch[1].trim() : "Unknown";

    return { name, email };
  };

  // const filteredLogs = chatLogs.filter((log) => {
  //   const { name, email } = extractNameAndEmail(log.message);
  //   return (
  //     name.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     email.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  // });

  // const sortedLogs = [...filteredLogs].sort(
  //   (a, b) =>
  //     new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  // );

  // const indexOfLastLog = currentPage * logsPerPage;
  // const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = logsByPage[currentPage] || [];

  const totalPages = Math.ceil(totalChats / logsPerPage);

  const CustomTooltip: React.FC<TooltipProps<number, string>> = ({
    active,
    payload,
  }) => {
    if (active && payload && payload.length) {
      const { name, total } = payload[0].payload as PieChartData;
      return (
        <div className="bg-white dark:bg-gray-800 p-2 border rounded shadow-sm">
          <p className="text-sm font-semibold text-gray-900 dark:text-gray-200">
            {name}
          </p>
          <p className="text-sm text-gray-700 dark:text-gray-300">
            Total Visits: {total}
          </p>
        </div>
      );
    }
    return null;
  };

  const handleView = (id: number) => {
    navigate(`/chats`, { state: { id } });
  };

  const handleDelete = (id: number) => {
    setSelectedLogId(id);
    setSuccessMessage(""); // Clear any previous success message
    setIsModalOpen(true);
  };

  const confirmDelete = async () => {
    if (selectedLogId !== null) {
      const success = await deleteChat(selectedLogId);
      if (success) {
        setSuccessMessage("Chat log deleted successfully.");
        
        // Fetch updated logs after deletion
        const offset = (currentPage - 1) * logsPerPage;
        const chatLogs = await getChatLogs(logsPerPage, offset);
        setLogsByPage((prevLogs) => ({
          ...prevLogs,
          [currentPage]: chatLogs || [],
        }));
      } else {
        setSuccessMessage("Failed to delete chat log.");
      }
    }
  };
  
  

  const csvData = currentLogs.map((log) => {
    const { name, email } = extractNameAndEmail(log.message);
    return { name, email, country: log.country, created_at: log.created_at };
  });
  
  return (
    <MainLayout>
      <div className="px-4 pt-6">
        {loading || dashboardLoading ? (
          <Spinner />
        ) : (
          <>
            {pieChartData.length > 1 && (
              <div className="mb-4">
                <label
                  htmlFor="domainSelect"
                  className="mr-2 text-gray-700 dark:text-gray-300"
                >
                  Select Domain:
                </label>
                <select
                  id="domainSelect"
                  value={selectedDomain}
                  onChange={handleDomainChange}
                  className="p-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200"
                >
                  <option value="All">All</option>
                  {pieChartData.map((domain) => (
                    <option key={domain.name} value={domain.name}>
                      {domain.name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full">
              <div className="flex justify-between items-center p-4 border border-gray-200 dark:border-gray-700 rounded-lg bg-white dark:bg-gray-800 shadow-sm w-full h-28">
                <div className="flex flex-col">
                  <span className="text-xs text-gray-500 dark:text-gray-400 uppercase">
                    Total Chats
                  </span>
                  <span className="text-3xl text-black dark:text-white">
                    {totalChats}
                  </span>
                </div>
                <div className="flex items-center justify-center w-12 h-12 bg-yellow-50 rounded-md">
                  <FaComments className="w-6 h-6 text-primary" />
                </div>
              </div>
              <div className="flex justify-between items-center p-4 border border-gray-200 dark:border-gray-700 rounded-lg bg-white dark:bg-gray-800 shadow-sm w-full h-28">
                <div className="flex flex-col">
                  <span className="text-xs text-gray-500 dark:text-gray-400 uppercase">
                    Current Live Chats
                  </span>
                  <span className="text-3xl text-black dark:text-white">
                    {liveChats}
                  </span>
                </div>
                <div className="flex items-center justify-center w-12 h-12 bg-yellow-50  rounded-md">
                  <FaCommentDots className="w-6 h-6 text-primary " />
                </div>
              </div>
              <div className="flex justify-between items-center p-4 border border-gray-200 dark:border-gray-700 rounded-lg bg-white dark:bg-gray-800 shadow-sm w-full h-28">
                <div className="flex flex-col">
                  <span className="text-xs text-gray-500 dark:text-gray-400 uppercase">
                    Average Chatbot Rating
                  </span>

                  <span className="text-3xl text-black dark:text-white">
                    {averageRating ? `${averageRating}/5` : "None"}
                  </span>
                </div>
                <div className="flex items-center justify-center w-12 h-12 bg-yellow-50 rounded-md">
                  <FaStar className="w-6 h-6 text-primary" />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full mt-8">
              <div
                className={`p-4 border border-gray-200 dark:border-gray-700 rounded-lg bg-white dark:bg-gray-800 shadow-sm ${
                  pieChartData.length > 1 ? "col-span-2" : "col-span-3"
                }`}
              >
                <h2 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-200">
                  Last 7 Days Chat Sessions
                </h2>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={getFilteredData()}>
                    <CartesianGrid
                      strokeDasharray="3 3"
                      className="stroke-gray-300 dark:stroke-gray-700"
                    />
                    <XAxis
                      dataKey="date"
                      className="text-gray-900 dark:text-gray-200"
                    />
                    <YAxis className="text-gray-900 dark:text-gray-200" />
                    <RechartsTooltip
                      contentStyle={{
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                        borderColor: "#ccc",
                      }}
                    />
                    {Object.keys(getFilteredData()[0] || {})
                      .filter((key) => key !== "date")
                      .map((key, index) => (
                        <Bar
                          key={key}
                          dataKey={key}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                  </BarChart>
                </ResponsiveContainer>
              </div>
              {pieChartData.length > 1 && (
                <div className="col-span-1 p-4 border border-gray-200 dark:border-gray-700 rounded-lg bg-white dark:bg-gray-800 shadow-sm">
                  <h2 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-200">
                    Last 7 Days Domain Performance
                  </h2>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={pieChartData}
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        fill="#82ca9d"
                        dataKey="value"
                        label={({ name, value }) => `${name}: ${value}%`}
                      >
                        {pieChartData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}-${entry.name}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <RechartsTooltip content={<CustomTooltip />} />
                      <Legend className="text-gray-900 dark:text-gray-200" />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              )}
            </div>

            <div className="border border-gray-200 dark:border-gray-700 rounded-lg bg-white dark:bg-gray-800 shadow-sm w-full mt-8">
              <div className="flex justify-between items-center border-b p-4 border-gray-200 dark:border-gray-700">
                <span className="text-lg font-semibold text-gray-900 dark:text-gray-200">
                  Chat Logs
                </span>

                <ExportCSV data={csvData} filename="chatlogs.csv" />
              </div>
              <div className="p-4">
                <div className="flex justify-between items-center mb-4">
                  <input
                    type="text"
                    placeholder="Search by name or email"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="p-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200"
                  />
                  <select
                    value={logsPerPage}
                    onChange={handleLogsPerPageChange}
                    className="p-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200"
                  >
                    {[10, 25, 50, 100].map((num) => (
                      <option key={num} value={num}>
                        Show {num}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="overflow-x-auto">
                  <Table hoverable>
                    <Table.Head>
                      <Table.HeadCell className="text-gray-900 dark:text-gray-200">
                        SI
                      </Table.HeadCell>
                      <Table.HeadCell className="text-gray-900 dark:text-gray-200">
                        Name
                      </Table.HeadCell>
                      <Table.HeadCell className="text-gray-900 dark:text-gray-200">
                        Email
                      </Table.HeadCell>
                      <Table.HeadCell className="text-gray-900 dark:text-gray-200">
                        Date
                      </Table.HeadCell>
                      <Table.HeadCell className="text-gray-900 dark:text-gray-200">
                        Country
                      </Table.HeadCell>
                      <Table.HeadCell className="text-gray-900 dark:text-gray-200">
                        Rating
                      </Table.HeadCell>
                      <Table.HeadCell className="text-gray-900 dark:text-gray-200">
                        Actions
                      </Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y divide-gray-200 dark:divide-gray-700">
                      {currentLogs.length ? (
                        currentLogs.map((log, index) => {
                          // Calculate the global index based on pagination
                          const globalIndex = (currentPage - 1) * logsPerPage + index + 1;

                          return (
                            <Table.Row
                              key={log.id}
                              className="hover:bg-gray-50 dark:hover:bg-gray-700"
                            >
                              <Table.Cell className="text-gray-900 dark:text-gray-200">
                                {globalIndex}
                              </Table.Cell>
                              <Table.Cell className="text-gray-900 dark:text-gray-200">
                                {extractNameAndEmail(log.message).name}
                              </Table.Cell>
                              <Table.Cell className="text-gray-900 dark:text-gray-200">
                                {extractNameAndEmail(log.message).email}
                              </Table.Cell>
                              <Table.Cell className="text-gray-900 dark:text-gray-200">
                                {moment
                                  .utc(log.created_at)
                                  .tz(moment.tz.guess())
                                  .format("MMM DD, YYYY, hh:mm a")}
                              </Table.Cell>
                              <Table.Cell className="text-gray-900 dark:text-gray-200">
                                {log.country}
                              </Table.Cell>
                              <Table.Cell className="text-gray-900 dark:text-gray-200">
                                {log.rating === ""
                                  ? "No rating given"
                                  : log.rating}
                              </Table.Cell>
                              <Table.Cell>
                                <button
                                  onClick={() => handleView(log.id)}
                                  className="text-blue-500 hover:underline"
                                >
                                  View
                                </button>
                                <button
                                  onClick={() => handleDelete(log.id)}
                                  className="text-red-500 hover:underline ml-2"
                                >
                                  Delete
                                </button>
                              </Table.Cell>
                            </Table.Row>
                          );
                        })
                      ) : (
                        <Table.Row>
                          <Table.Cell
                            colSpan={7}
                            className="text-center p-4 text-gray-900 dark:text-gray-200"
                          >
                            No chat logs available.
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Table.Body>
                  </Table>
                </div>
                <div className="flex justify-between items-center mt-4">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(page) => setCurrentPage(page)}
                    className="flex justify-center mt-4"
                  />
                </div>
              </div>
            </div>
          </>
        )}
<Modal
  show={isModalOpen}
  onClose={() => {
    setIsModalOpen(false);
    setSuccessMessage(""); // Reset the message when closing the modal
  }}
  size="md"
  popup
>
  <Modal.Header />
  <Modal.Body>
    <div className="text-center">
      {successMessage ? (
        <>
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            {successMessage}
          </h3>
          <div className="flex justify-center">
            <Button color="gray" onClick={() => setIsModalOpen(false)}>
              Close
            </Button>
          </div>
        </>
      ) : (
        <>
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            Are you sure you want to delete this chat log?
          </h3>
          <div className="flex justify-center gap-4">
            <Button color="failure" onClick={confirmDelete}>
              Yes, I'm sure
            </Button>
            <Button color="gray" onClick={() => setIsModalOpen(false)}>
              No, cancel
            </Button>
          </div>
        </>
      )}
    </div>
  </Modal.Body>
</Modal>


      </div>
    </MainLayout>
  );
};

export default Dashboard;
