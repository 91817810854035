import React, { useState } from 'react';
import './index.css';
import {  menu_icon, message_icon, plus_icon, back_arrow, trash_icon } from '../../assets/images/index.js';
import {  useNavigate } from 'react-router-dom';

interface Part {
  text: string;
}

interface Conversation {
  role: string;
  parts: Part[];
}

interface SavedConversation {
  sessionId: string;
  conversationHistory: Conversation[];
}

interface SidebarProps {
  savedConvos: SavedConversation[];
  chatIndex: number;
  onNewChat: () => void;
  onChatSelect: (index: number) => void;
  onDeleteChat: (sessionId: string) => void; // Add delete handler prop
}

const Sidebar: React.FC<SidebarProps> = ({ savedConvos, chatIndex, onNewChat, onChatSelect, onDeleteChat }) => {
  const [menu, setMenu] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [chatToDelete, setChatToDelete] = useState<string | null>(null);
 const navigate = useNavigate();
  const handleDeleteClick = (sessionId: string) => {
    setChatToDelete(sessionId);
    setShowModal(true);
  };

  const confirmDelete = () => {
    if (chatToDelete) {
      onDeleteChat(chatToDelete);
    }
    setShowModal(false);
  };

  return (
    <>
      <div className={`sideBar ${menu ? 'expanded' : ''}`}>
        <div className="upperSide">
          <button className='menu' onClick={() => setMenu(prev => !prev)}>
            <img src={menu_icon} alt="" />
          </button>
          <button className='plus plus1' onClick={onNewChat}>
            <img src={plus_icon} className='plusImg' alt="" />
            {menu && "New Chat"}
          </button>
          {menu && (
            <div className="recent">
              <span className='recentText'>Past Conversations</span>
              <div className="scroll">
                {savedConvos.map((conv, index) => (
                  <div className="recentTabs" key={conv.sessionId}>
                   <button
  className={chatIndex === index ? "high tab flex justify-between items-center" : "tab flex justify-between items-center"}
  onClick={() => onChatSelect(index)}
>
  <div className="flex-grow flex items-center">
    <img src={message_icon} alt="" />
    <span className="ml-2">
      {conv.conversationHistory.length > 0 ? conv.conversationHistory[0].parts[0].text.slice(0, 15) : 'New Chat'}...
    </span>
  </div>
  <button className="deleteBtn ml-2" onClick={(e) => {
      e.stopPropagation(); // Prevent the main button's onClick from firing
      handleDeleteClick(conv.sessionId);
    }}>
    <img src={trash_icon} alt="Delete" />
  </button>
</button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="lowerSide">
          <button className='btns' onClick={() => navigate("/")}><img src={back_arrow} alt="" />{menu && "Back to Home"}</button>
          
        </div>
      </div>

      {showModal && (
        <div className="modal">
          <div className="modalContent">
            <h3>Are you sure you want to delete this chat?</h3>
            <button className="modalBtn" onClick={confirmDelete}>Yes</button>
            <button className="modalBtn" onClick={() => setShowModal(false)}>No</button>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
