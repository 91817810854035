import React, { useState, useMemo } from "react";
import { Button, Table, TextInput, Pagination, Modal } from "flowbite-react";
import {
  FaSort,
  FaSortUp,
  FaSortDown,
  FaEye,
  FaEdit,
  FaTrash,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { DomainData } from "../../../utils/types/domains";
import { deleteDomain } from "../../../api/domain";
import { User } from "../../../utils/types/authentication";

interface DomainsTableProps {
  domains: DomainData[] | null | undefined;
  user: User;
  setDomains: React.Dispatch<React.SetStateAction<DomainData[]>>; // Add this line
}


const DomainsTable: React.FC<DomainsTableProps> = ({ domains, user, setDomains }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState<{
    key: keyof DomainData;
    direction: "asc" | "desc";
  } | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [domainToDelete, setDomainToDelete] = useState<DomainData | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteResponse, setDeleteResponse] = useState<string | null>(null);
  const navigate = useNavigate();
  const itemsPerPage = 5;

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleSort = (key: keyof DomainData) => {
    let direction: "asc" | "desc" = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedDomains = useMemo(() => {
    if (!domains) return [];
    const sortableDomains = [...domains];
    if (sortConfig !== null) {
      sortableDomains.sort((a, b) => {
        const aValue = a[sortConfig.key] ?? "";
        const bValue = b[sortConfig.key] ?? "";
        if (aValue < bValue) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableDomains;
  }, [domains, sortConfig]);

  const filteredDomains = sortedDomains.filter((domain) =>
    domain.chat_bot_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredDomains.length / itemsPerPage);
  const displayedDomains = filteredDomains.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleDeleteClick = (domain: DomainData) => {
    setDomainToDelete(domain);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    if (domainToDelete && domainToDelete.domain) {
      setIsDeleting(true);
      const result = await deleteDomain(domainToDelete.domain);
      setIsDeleting(false);
  
      if (result === "Domain deleted successfully") {
        setDeleteResponse("Domain deleted successfully");
  
        // Update the domains state to remove the deleted domain
        const updatedDomains = domains?.filter(
          (domain) => domain.domain !== domainToDelete.domain
        );
        setDomainToDelete(null); // Clear the selected domain
        setShowModal(false); // Close the modal
  
        // Update the table with the new domain list
        if (updatedDomains) {
          setDomains(updatedDomains); // Use setDomains to update the state
        }
      } else {
        setDeleteResponse("An error occurred while deleting the domain.");
      }
    }
  };
  
  

  const closeModal = () => {
    setShowModal(false);
    setDomainToDelete(null);
    setDeleteResponse(null);
  };

  const handleViewOrEdit = (domain: DomainData) => {
    navigate("/domains/view", { state: { domain } });
  };

  const handleTraining = (domain: DomainData) => {
    navigate("/domains/chatbot-training", { state: { domain } });
  };

  return (
    <div>
      <div className="flex justify-between mb-4">
        {domains && domains.length >= user.plan_number_of_custom_domains && user.plan_number_of_custom_domains != -1 ? (
          <span className="text-red-500 font-semibold">
            You have reached the maximum number of allowed domains.
          </span>
        ) : (
          <Button onClick={() => navigate("/domains/add")}>Add Domain</Button>
        )}
        <TextInput
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <div className="overflow-x-auto">
        <Table className="min-w-full bg-white dark:bg-gray-800">
          <Table.Head>
            <Table.HeadCell
              onClick={() => handleSort("website")}
              className="cursor-pointer w-1/6"
            >
              Website
              {sortConfig?.key === "website" ? (
                sortConfig.direction === "asc" ? (
                  <FaSortUp />
                ) : (
                  <FaSortDown />
                )
              ) : (
                <FaSort />
              )}
            </Table.HeadCell>
            <Table.HeadCell
              onClick={() => handleSort("country")}
              className="cursor-pointer w-1/6"
            >
              Country
              {sortConfig?.key === "country" ? (
                sortConfig.direction === "asc" ? (
                  <FaSortUp />
                ) : (
                  <FaSortDown />
                )
              ) : (
                <FaSort />
              )}
            </Table.HeadCell>
            <Table.HeadCell
              onClick={() => handleSort("company")}
              className="cursor-pointer w-1/6"
            >
              Company
              {sortConfig?.key === "company" ? (
                sortConfig.direction === "asc" ? (
                  <FaSortUp />
                ) : (
                  <FaSortDown />
                )
              ) : (
                <FaSort />
              )}
            </Table.HeadCell>
            <Table.HeadCell
              onClick={() => handleSort("chat_bot_name")}
              className="cursor-pointer w-1/6"
            >
              Chat Bot Name
              {sortConfig?.key === "chat_bot_name" ? (
                sortConfig.direction === "asc" ? (
                  <FaSortUp />
                ) : (
                  <FaSortDown />
                )
              ) : (
                <FaSort />
              )}
            </Table.HeadCell>
            <Table.HeadCell className="w-1/6">Chatbot Training</Table.HeadCell>
            <Table.HeadCell className="w-1/6">Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {displayedDomains.length === 0 ? (
              <Table.Row>
                <Table.Cell colSpan={6} className="text-center py-4">
                  No data available at the moment.
                </Table.Cell>
              </Table.Row>
            ) : (
              displayedDomains.map((domain) => (
                <Table.Row key={domain.website}>
                  <Table.Cell>{domain.website}</Table.Cell>
                  <Table.Cell>{domain.country}</Table.Cell>
                  <Table.Cell>{domain.company}</Table.Cell>
                  <Table.Cell>{domain.chat_bot_name}</Table.Cell>
                  <Table.Cell>
                    <Button size="xs" onClick={() => handleTraining(domain)}>
                      Train
                    </Button>
                  </Table.Cell>
                  <Table.Cell className="flex space-x-4">
                    <FaEye
                      className="cursor-pointer text-blue-500 text-xl"
                      onClick={() => handleViewOrEdit(domain)}
                    />
                    <FaEdit
                      className="cursor-pointer text-yellow-500 text-xl"
                      onClick={() => handleViewOrEdit(domain)}
                    />
                    <FaTrash
                      className="cursor-pointer text-red-500 text-xl"
                      onClick={() => handleDeleteClick(domain)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))
            )}
          </Table.Body>
        </Table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
        className="mt-4"
      />

      <Modal show={showModal} onClose={closeModal}>
        <Modal.Header>Confirm Deletion</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            {deleteResponse ? (
              <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                {deleteResponse}
              </p>
            ) : (
              <>
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  Are you sure you want to delete this domain? All data since
                  the creation of the chatbot on this domain will be erased.
                </p>
                <p className="text-base font-bold text-gray-700 dark:text-gray-200">
                  {domainToDelete?.website}
                </p>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {deleteResponse ? (
            <Button onClick={closeModal}>Close</Button>
          ) : (
            <>
              <Button color="red" onClick={confirmDelete} disabled={isDeleting}>
                {isDeleting ? "Deleting..." : "Delete"}
              </Button>
              <Button onClick={closeModal}>Cancel</Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DomainsTable;
