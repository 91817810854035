import axios from "./axiosConfig";

import { isAxiosError } from "axios";

// Define the interface for the body parameters
interface ChatRequestData {
  session_id: string;
  message: string;
}

// Define the response structure from the API if known
interface ChatResponse {
  message: string;
}

export const sendMessageToChatGPT = async (data: ChatRequestData): Promise<string | null> => {
  try {
    const response = await axios.post<ChatResponse>("/chatgpt", data);
    if (response.status === 200 && response.data.message) {
      return response.data.message;  // Assuming the API returns the response text in this structure
    }
    return "An error occurred";
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      console.error("Error in chat response:", error.response.data);
    } else {
      console.error("Error in chat request:", error);
    }
    return null;
  }
};

// Example of using the refactored function
export const run = async (session_id: string, message: string): Promise<string | null> => {
  return await sendMessageToChatGPT({ session_id, message });
};
