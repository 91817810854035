import React, { useState, useEffect, useRef } from 'react';
import './index.css';
import { sendMessageToChatGPT } from '../../api/gemini.js';
import { bulb_icon, code_icon, compass_icon,  gemini_icon, message_icon, send_icon, user_icon } from '../../assets/images/index.js';
import Sidebar from '../../components/ChatInterfaceSidebar/index.js';

interface Part {
  text: string;
}

interface Conversation {
  role: 'user' | 'model';
  parts: Part[];
}

interface SavedConversation {
  sessionId: string;
  conversationHistory: Conversation[];
}

const ChatApp: React.FC = () => {
  const [input, setInput] = useState<string>("");
  const [conversationHistory, setConversationHistory] = useState<Conversation[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [savedConvos, setSavedConvos] = useState<SavedConversation[]>([]);
  const [chatIndex, setChatIndex] = useState<number>(0);
  const bottomRef = useRef<HTMLDivElement | null>(null);

  // Load initial data from localStorage
  useEffect(() => {
    const storedConvos: SavedConversation[] = JSON.parse(localStorage.getItem("savedConvos") || '[]');
    setSavedConvos(storedConvos);
    if (storedConvos.length > 0) {
      setChatIndex(storedConvos.length - 1);
      setConversationHistory(storedConvos[storedConvos.length - 1].conversationHistory);
    }
  }, []);

  useEffect(() => {
    if (conversationHistory.length > 0 && savedConvos.length > 0) {
      const updatedConvos = [...savedConvos];
      updatedConvos[chatIndex] = {
        ...updatedConvos[chatIndex],
        conversationHistory: conversationHistory,
      };
      setSavedConvos(updatedConvos);
      localStorage.setItem('savedConvos', JSON.stringify(updatedConvos));
    }
  }, [conversationHistory]);

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [conversationHistory, loading]);

  const handleRun = async (): Promise<void> => {
    if (input !== "") {
      setConversationHistory(prev => [
        ...prev,
        { role: "user", parts: [{ text: input }] }
      ]);
      const prompt = input;
      setInput("");
      setLoading(true);

      const response = await sendMessageToChatGPT({ session_id: savedConvos[chatIndex].sessionId, message: prompt });

      setLoading(false);
      if (response) {
        setConversationHistory(prevHistory => [
          ...prevHistory,
          { role: "model", parts: [{ text: response }] }
        ]);
      }
    }
  };

  const handleNewChat = (): void => {
    const newSessionId = `session-${Date.now()}`;
    const newConversation: SavedConversation = {
      sessionId: newSessionId,
      conversationHistory: []
    };
    const updatedConvos = [...savedConvos, newConversation];
    setSavedConvos(updatedConvos);
    setChatIndex(updatedConvos.length - 1);
    setConversationHistory([]);
    localStorage.setItem('savedConvos', JSON.stringify(updatedConvos));
  };

  const handleChatSelect = (index: number): void => {
    setChatIndex(index);
    setConversationHistory(savedConvos[index].conversationHistory);
  };

  const handleDeleteChat = (sessionId: string) => {
    setSavedConvos((prevConvos) => {
      const updatedConvos = prevConvos.filter((convo) => convo.sessionId !== sessionId);
      localStorage.setItem('savedConvos', JSON.stringify(updatedConvos));
      return updatedConvos;
    });
  
    // If the deleted conversation was the currently selected one, reset the conversationHistory and chatIndex
    if (savedConvos[chatIndex]?.sessionId === sessionId) {
      setConversationHistory([]);
      setChatIndex(0);
    }
  };

  return (
    <div className='main'>
      <Sidebar
        savedConvos={savedConvos}
        chatIndex={chatIndex}
        onNewChat={handleNewChat}
        onChatSelect={handleChatSelect}
        onDeleteChat={handleDeleteChat}
      />
      <div className='App'>
        <div className="top">
          <span className='title'>Rupeni AI</span>
        </div>
        <div className={conversationHistory.length === 0 ? "midContainer" : "chatContainer"}>
          {conversationHistory.length !== 0 ? conversationHistory.map((convo, index) => {
            if (loading && conversationHistory.length - 1 === index) {
              const convoKey = `convo-${index}`;
              return (
                <div key={convoKey}>
                  <div className="chat">
                    {convo.role === "user"
                      ? <img src={user_icon} className='chatImg' alt="" />
                      : <img src={gemini_icon} className='chatImg' alt="" />}
                    <span
                      className={index % 2 !== 0 ? "chatText lighter" : "chatText"}
                      ref={conversationHistory.length - 1 === index ? bottomRef : null}
                      dangerouslySetInnerHTML={{ __html: convo.parts[0].text }}>
                    </span>
                  </div>
                  <div className="chat">
                    <img src={gemini_icon} className='chatImg rotateImg' alt="" />
                    <div className="loader" ref={conversationHistory.length - 1 === index ? bottomRef : null}>
                      <hr />
                      <hr />
                      <hr />
                    </div>
                  </div>
                </div>
              )
            } else {
              return (
                <div className="chat" key={index}>
                  {convo.role === "user"
                    ? <img src={user_icon} className='chatImg' alt="" />
                    : <img src={gemini_icon} className='chatImg gemImg' alt="" />}
                  <span
                    className={index % 2 !== 0 ? "chatText lighter" : "chatText"}
                    ref={conversationHistory.length - 1 === index ? bottomRef : null}
                    dangerouslySetInnerHTML={{ __html: convo.parts[0].text }}>
                  </span>
                </div>
              )
            }
          })
            : <>
              <div className="greet">
                <span className='colorGreet'>Bula!</span>
                <span className=''>Au rawa ni vakavuna na cava ena siga nikua?</span>
              </div>
              <div className="suggestion">
                  <button className='sgBtn' onClick={() => setInput("What are some unique expressions in the Fijian language?")}>
                  <span className='sgTxt'>What are some unique expressions in the Fijian language?</span>
                  <img className='sgImg' src={bulb_icon} alt="" />
                  </button>
                  <button className='sgBtn' onClick={() => setInput("How does the Fijian language influence place names in Fiji?")}>
                  <span className='sgTxt'>How does the Fijian language influence place names in Fiji?</span>
                 <img className='sgImg' src={compass_icon} alt="" />
                </button>
                  <button className='sgBtn' onClick={() => setInput("Suggest Fijian phrases for welcoming guests during cultural events")}>
                  <span className='sgTxt'>Suggest Fijian phrases for welcoming guests during cultural events</span>
                  <img className='sgImg' src={message_icon} alt="" />
                  </button>
                 <button className='sgBtn' onClick={() => setInput("Explain the structure and importance of Fijian verbs")}>
                  <span className='sgTxt'>Explain the structure and importance of Fijian verbs</span>
                   <img className='sgImg pblm1' src={code_icon} alt="" />
                 </button>
                  </div>

            </>}
        </div>
        <div className='Footer'>
          <div className="Search">
            <input
              
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  handleRun()
                }
              }}
              placeholder='Ask Me Anything...' className="searchBar" />
          
            {input !== "" ?
              <button className='srhImg'
                onClick={() =>
                  handleRun()}
              ><img src={send_icon}
                alt="send icon" />
              </button> : ""}
          </div>
          <div className='info'>
          Please verify the accuracy of Rupeni AI's responses, as they may contain errors, especially regarding personal information.
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatApp;
